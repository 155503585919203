<template>
  <div
    class="rir-map"
    ref="map"
    :class="{'cursor-crosshair': polygonDrawing}"
  >
    <yandex-map
      v-if="isYmapsReady"
      :coords="$cityCenter || center"
      :zoom="mapZoom"
      @click="onMapClick"
      :options="mapOptions"
      :controls="mapControls"
      :map-type="layersSelected.type"
      @dblclick="(e)=>{e.preventDefault()}"
      @input="onMapInitLo"
      @map-was-initialized="onMapInit"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
      :cluster-options="clusterOptions"
      :cluster-callbacks="clickOnCluster"
      :use-object-manager="true"
      @actionend="$emit('actionEnd', $event)"
      :balloon-component="balloonComponent"
    >
      <slot />

      <!-- <ymap-marker
        v-if="!markers.length"
        :coords="coords"
        marker-id="123"
        @click="onMapClick"
        :icon="markerIcon"
        :options="{ draggable: markerDrag }"
        @dragend="onDragMarker"
      >
      </ymap-marker>
      <ymap-marker
        v-else
        v-for="(marker, index) in markers"
        :key="marker.id"
        :coords="[`${marker.lat}`, `${marker.lng}`]"
        :marker-id="index"
        @click="zoom = zoom"
        :icon="markerIcon"
        :options="{ hideIconOnBalloonOpen: false }"
      >
        <BalloonCard
          slot="balloon"
          :title="marker.name"
          :address="marker.address"
          :img="marker.images[0]"
        />
      </ymap-marker> -->
    </yandex-map>
    <div
      v-show="control"
      :class="isCollapsed ? 'rir-map__controls' : 'rir-map__controls--fixed'"
    >
      <r-button-simple
        v-if="collapseBtn"
        class="mb-4 amelie"
        icon="fullscreen"
        type="secondary"
        size="larishae"
        icon-size="20"
        @click="isCollapsed = !isCollapsed"
        title=""
      />
      <r-button-simple
        icon="add"
        class="amelie"
        type="secondary"
        size="larishae"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        class="mt-1 mb-4 amelie"
        icon="remove"
        type="secondary"
        size="larishae"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        v-if="drawPolygon"
        class="mt-1 mb-4 amelie"
        icon="add-directory"
        :type="polygonDrawing ? 'primary' : 'secondary'"
        size="larishae"
        icon-size="20"
        @click="loadPoligon"
        title=""
      />
      <!-- <r-tooltip
           activator-hover
           color="amelie"
       >
         <template #activator>
           <r-button v-if="!isCovid" icon="add-directory" :class="(poligon ? 'rocky' : 'amelie')"
                       :fill="(poligon ? 'joker' : 'rocky')" iconSize="20" @click="loadPoligon"/>
         </template>
         <div class="text-titanic">Построить полигон</div>
       </r-tooltip>-->
      <div class="layers_dropdown">
        <r-button-simple
          icon="layers"
          class="mt-1 mb-4 amelie"
          type="secondary"
          size="larishae"
          fill="rocky"
          icon-size="20"
          @click="layersSwither = !layersSwither"
          title=""
        />
        <div
          class="layers_dropdown__list"
          v-if="layersSwither"
        >
          <div
            class="layers_dropdown__list_item sulguni opacity-72"
            v-for="layer of layers"
            :class="{'active': layer.id === layersSelected.id}"
            @click="onSelectLayer(layer)"
            :key="layer.id"
          >
            {{ layer.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';

export default {
  name: 'RirMap',
  components: {
    loadYmap
  },
  props: {
    drawPolygon: {
      type: Boolean,
      default: false
    },
    balloonComponent: {
      type: Object,
      default: () => ({})
    },
    control: {
      type: Boolean,
      default: true
    },
    covids: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: true
    },
    center: {
      type: Array,
      default: () => [47.513572, 42.175165]
    },
    zoom: {
      type: Number,
      default: 14
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 12
    },
    // Перетаскивание маркера
    markerDrag: {
      type: Boolean,
      default: false
    },
    isCovid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      polygonDrawing: false,
      isYmapsReady: false, // Начальное значение
      mapControls: ['fullscreenControl'],
      counts: 64, // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },
      poligon: false,
      //   Иконка маркера
      //   markerIcon: {
      //     layout: 'default#image',
      //     imageHref: process.env.VUE_APP_MAP_MARKER_ICON,
      //     imageSize: [24, 36],
      //     imageOffset: [0, 0]
      //   },
      clickOnCluster: {
        cluster: {
          click(e) {
            const target = e.get('target');
            console.log(target);
            /* const target = e.get('target');
            const customItemContentLayout = window.ymaps.templateLayoutFactory.createClass(
              '<div>{{geoObject.properties.balloonContentBody|raw}}</div>'
            );
            target.options.set({ clusterBalloonItemContentLayout: customItemContentLayout }); */
          }
        }
      },
      clusterOptions: {

        cluster: {
          clusterDisableClickZoom: false,
          clusterOpenBalloonOnClick: true,
          clusterize: true,
          clusterHasHint: true,
          // ObjectManager принимает те же опции, что и кластеризатор.
          gridSize: 128,
          /*   clusterIconLayout: 'default#pieChart',
             iconPieChartCoreFillStyle: '#3D75E4',
             clusterIconPieChartStrokeWidth:6,
             clusterIconPieChartCoreRadius: 20,
             clusterIconPieChartRadius: 28, */
          clusterBalloonLayout: [
            '<ul class="list" style="margin: 24px;max-width:250px;  max-height: 200px;overflow-y: scroll;">',
            '{% for geoObject in properties.geoObjects %}',
            '<li class="mt-2">'
            + '<a href="/admin/gis-zkh/edit/{{ geoObject.properties.balloonContentHeader.id|raw }}" class="list_item" >'
            + '{{ geoObject.properties.balloonContentHeader.name|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join('')
        },
        covid: {
          hideIconOnBalloonOpen: false,
          clusterize: true,
          clusterHasHint: true,
          // ObjectManager принимает те же опции, что и кластеризатор.
          gridSize: 128,
          clusterDisableClickZoom: true,
          clusterBalloonLayout: [
            '<ul class="list" style="margin: 24px;max-width:250px;  max-height: 200px;overflow-y: scroll;">',
            '{% for geoObject in properties.geoObjects %}',
            '<li class="mt-2">'
            + '<a href="/admin/gis-zkh/edit/{{ geoObject.properties.balloonContentHeader.id|raw }}" class="list_item" >'
            + '{{ geoObject.properties.balloonContentHeader.name|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join('')
          // Иконка кластера
          /* clusterIcons: [
            {
              href: process.env.VUE_APP_MAP_COVID_ICON,
              size: [64, 64],
              offset: [0, 0],
            }
          ], */
          //   //   Шрифт кластера
        }
      },
      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true,
      layersSwither: false,
      yaMapPoint: null,
      yaMapPointEdge: null,
      layersSelected: {
        id: 1,
        title: 'Схема',
        type: 'map'
      },
      layers: [
        {
          id: 1,
          title: 'Схема',
          type: 'map'
        },
        {
          id: 2,
          title: 'Спутник',
          type: 'satellite'
        },
        {
          id: 3,
          title: 'Гибрид',
          type: 'hybrid'
        }
      ],
      objectManagerPolygon: {},
      polygon: {},
      balloonContentLayout: ''
    };
  },

  watch: {
    isCollapsed() {
      if (!this.isCollapsed) {
        this.$refs.map.querySelector('.ymaps-2-1-79-float-button-icon_icon_expand').click();
      } else {
        document.querySelector('.ymaps-2-1-79-float-button-icon_icon_collapse').click();
      }
    }
  },
  async mounted() {
    // настройки карты
    const settings = {
      apiKey: '0f707b9a-62ed-46b5-98ea-b56b14c0fc31',
      coordorder: 'latlong'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },
  updated() {
    // this.mapInstanse.container.fitToViewport();

    // this.$nextTick(() => {
    //   //   this.$forceUpdate();
    //   //   this.$slots = tt;
    // //   console.log('tik-tok', this.$refs.map.parentElement.height);
    // });

    // this.$forceUpdate();
  },
  methods: {
    onSelectLayer(layer) {
      this.layersSelected = layer;
      this.layersSwither = false;
      this.mapInstanse.setType(`yandex#${layer.type}`, {
        checkZoomRange: true
      });
    },

    onMapInitLo() {
      let vara = process.env.VUE_APP_MAP_CLUSTER_ICON;
      if (this.covids == true) {
        vara = process.env.VUE_APP_MAP_COVID_ICON;
      }
      return vara;
    },
    // onMapExpand() {
    //   this.isCollapsed = !this.isCollapsed;

    //   if (this.isCollapsed) {
    //     this.$refs.map.querySelector('.ymaps-2-1-79-float-button-icon_icon_expand').click();
    //   } else {
    //     this.$refs.map.querySelector('.ymaps-2-1-79-float-button-icon_icon_collapse').click();
    //   }
    // },
    onMapInit(e) {
      const _this = this;
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.yaMapPoint = window.ymaps.templateLayoutFactory.createClass(
        "<div class='ya-map-point'></div>"
      );
      this.yaMapPointEdge = window.ymaps.templateLayoutFactory.createClass(
        "<div class='ya-map-point--edge'></div>"
      );
      if (this.drawPolygon) {
        this.objectManagerPolygon = new window.ymaps.ObjectManager({
          // Чтобы метки начали кластеризоваться, выставляем опцию.
          clusterize: false
        });
        // Чтобы задать опции одиночным объектам и кластерам,
        // обратимся к дочерним коллекциям ObjectManager.
        this.mapInstanse.options.set('openBalloonOnClick', false);
        this.mapInstanse.geoObjects.add(this.objectManagerPolygon);
        // console.log('this.mapInstanse.options', this.mapInstanse.options)
        this.balloonContentLayout = ymaps.templateLayoutFactory.createClass(
          `
        <div class="rir-map-balloon poligoncount">
      <div class="rir-map-balloon__center">
        <div class="prints">
          <div class="rir-map-balloon__center__title">
            Домов
          </div>
          <div class="rir-map-balloon__center__text sulguni">
            -
          </div>
          <div class="rir-map-balloon__center__title">
            Жилых помещений
          </div>
          <div class="rir-map-balloon__center__text sulguni ">
            -
          </div>
          <div class="rir-map-balloon__center__title">
            Подъездов
          </div>
          <div class="rir-map-balloon__center__text sulguni ">
            -
          </div>
          <div class="rir-map-balloon__center__title">
            Прописанных жителей
          </div>
          <div class="rir-map-balloon__center__text sulguni">
            -
          </div>
        </div>
        <div
          class="float-left mr-6 print"
          onclick="
            printContent = document.querySelectorAll('div.prints')[0];
            WinPrint = window.open('', '', 'width=900,height=650');
            WinPrint.document.write(printContent.innerHTML);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();"
        >
          <r-icon
            icon="print"
            class="print"
            fill="#3D75E4"
            size="16"
          />
          Печать
        </div>
      </div>
    </div>
        `
        );
        this.addPolygon();
        // В режиме добавления новых вершин меняем цвет обводки многоугольника.

        // Включаем режим редактирования с возможностью добавления новых вершин.
        // ;
      }
    },
    onDragMarker(e) {
    },
    addPolygon() {
      this.polygon = new window.ymaps.Polygon([], {}, {
        // Курсор в режиме добавления новых вершин.
        editorDrawingCursor: 'grab',
        // Максимально допустимое количество вершин.
        editorMaxPoints: 20,
        // Цвет заливки.
        fillColor: '#3d75e4',
        fillOpacity: 0.16,
        // Цвет обводки.
        strokeColor: '#3d75e4',
        // Ширина обводки.
        strokeWidth: 1,
        editorVertexIconShape: {
          type: 'Rectangle',
          coordinates: [
            [-10, -10],
            [10, 10]
          ]
        },
        /** Недокументированная опция!
         Задает активную область над промежуточнй точкой (editorVertexLayout),
         тем самым включает взаимодействие с ней */
        editorEdgeIconShape: {
          type: 'Rectangle',
          coordinates: [
            [-10, -10],
            [10, 10]
          ]
        },
        editorVertexLayout: this.yaMapPoint,
        editorVertexLayoutHover: this.yaMapPoint,
        editorVertexLayoutActive: this.yaMapPoint,
        editorVertexLayoutDrag: this.yaMapPoint,
        editorEdgeLayout: this.yaMapPointEdge,
        editorEdgeLayoutHover: this.yaMapPointEdge,
        editorEdgeLayoutActive: this.yaMapPointEdge,
        editorEdgeLayoutDrag: this.yaMapPointEdge,
        hasBalloon: true,
        editorDrawNewInteriors: false,
        balloonContentLayout: this.balloonContentLayout
        // draggable: true
      });
      // Добавляем многоугольник на карту.
      this.mapInstanse.geoObjects.add(this.polygon);
      const stateMonitor = new window.ymaps.Monitor(this.polygon.editor.state);

      stateMonitor.add('editing', isEditing => {
        if (!isEditing) {
          this.$store.dispatch('loadPoly', {
            active: this.coords[0],
            pattern: this.$store.state.pattern,
            type: this.$store.state.type,
            serviceCompanyId: this.$store.state.serviceCompanyId
          }).then(res => {
            this.polygon.options.set('balloonContentLayout', window.ymaps.templateLayoutFactory.createClass(
              `
                <div class="rir-map-balloon poligoncount">
                  <div class="rir-map-balloon__center">
                    <div class="prints">
                      <div class="rir-map-balloon__center__title">
                        Домов
                      </div>
                      <div class="rir-map-balloon__center__text sulguni">
                        ${res.buildingCount ? res?.buildingCount : '-'}
                      </div>
                      <div class="rir-map-balloon__center__title">
                        Жилых помещений
                      </div>
                      <div class="rir-map-balloon__center__text sulguni ">
                         ${res?.livingRoom ? res?.livingRoom : '-'}
                      </div>
                      <div class="rir-map-balloon__center__title">
                        Подъездов
                      </div>
                      <div class="rir-map-balloon__center__text sulguni ">
                         ${res?.porch ? res?.porch : '-'}
                      </div>
                      <div class="rir-map-balloon__center__title">
                        Прописанных жителей
                      </div>
                      <div class="rir-map-balloon__center__text sulguni">
                        ${res?.peopleTotal ? res?.peopleTotal : '-'}
                      </div>
                    </div>
                    <div
                      class="float-left mr-6 print"
                      onclick="
                        printContent = document.querySelectorAll('div.prints')[0];
                        WinPrint = window.open('', '', 'width=900,height=650');
                        WinPrint.document.write(printContent.innerHTML);
                        WinPrint.document.close();
                        WinPrint.focus();
                        WinPrint.print();
                        WinPrint.close();"
                    >
                      <r-icon
                        icon="print"
                        class="print"
                        fill="#3D75E4"
                      />
                      Печать
                    </div>
                  </div>
                </div>
              `
            ));
          });
          this.polygon.balloon.open();
        }
      });
      this.polygon.events.add('geometrychange', _.throttle(() => {
        this.coords = this.polygon.geometry.getCoordinates();
        this.$emit('click', this.coords);
      }, 500));
      this.polygon.events.add('dblclick', () => {
        this.polygon.editor.startEditing();
      });
      this.polygon.events.add('click', () => {
        this.polygon.balloon.open();
      });
      this.polygon.events.add('dragend', () => {
        console.log('dragend');
      });
    },
    onZoom(val) {
      //   this.$forceUpdate();
      //   this.mapInstanse.container.fitToViewport();
      const newZoom = this.mapZoom + val;
      // this.mapZoom = newZoom;
      this.mapZoom = newZoom;
    },
    onMapClickToo() {
      this.mapInstanse.balloon.close();
    },
    onLocation() {
      this.mapInstanse.geoObjects.each(collection => {
        // console.log('geoObj',geoObject.properties.get('markerId'))

        const checkMarker = marker => {
          const markerId = marker.properties.get('markerId');
        };
        collection.each(checkMarker);
      });
    },
    onMapClick(e) {
      //   if (this.markers.length) return;
      const coords = e.get('coords');
      this.coords = coords;
      //   Закрываем открытый Balloon при клике по карте
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      // this.$emit('click', coords);
      /* if (this.drawPolygon) {
        this.polygon.editor.startDrawing();
      } */
    },
    loadPoligon() {
      if (this.drawPolygon) {
      /*   */
        this.polygonDrawing = !this.polygonDrawing;
        if (this.polygonDrawing) {
          this.addPolygon();
          console.log(this.polygon.geometry.getBounds());
          // if (this.polygon.geometry.getBounds() !== null) {
          //   this.mapInstanse.geoObjects.remove(this.polygon);
          // }
          this.mapInstanse.geoObjects.each(item => {
            if (item?.geometry?.getType() === 'Polygon') {
              if (item.geometry.getBounds() !== null) {
                this.mapInstanse.geoObjects.remove(item);
              }
            }
          });

          this.polygon.editor.startDrawing();
        } else {
          this.polygon.editor.stopEditing();
        }
      }

      /* this.poligon = !this.poligon;
      this.$emit('initPoligon', this.poligon, this.mapInstanse); */
    },
    onRightClick(e) {
      this.$emit('onRightClick', e);
      this.polygon.editor.stopEditing();
    },
    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
            // console.log('height', entry.contentRect.height);
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>


.layers_dropdown {
  position: relative;
  border-radius: 8px;

  &__list {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    min-width: 215px;
    background: #FFFFFF;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
    border-radius: 8px;
    padding: 12px 0;

    &_item {
      border-left: 4px solid transparent;
      padding: 12px 16px;
      cursor: pointer;
      transition: opacity .2s ease-in-out;

      &:hover {
        opacity: 1;
      }

      &.active {
        border-left-color: #3D75E4;
        opacity: 1;
      }
    }
  }
}

.rir-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);

    > * {
      background-color: #fff;
    }
  }

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .rir-map {
    &__search {
      display: none;
    }
  }
}

// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}

::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}

</style>

<style lang="scss">

.rir-map-balloon {
  width: 199px;
  height: 268px;
  &__center {
    display: table;
    margin-top: 20px;
    margin-left: 16px;
    width: 100%;
    &__title {
      position: static;
      width: 100%;
      height: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #04153E;
      opacity: 0.48;
    }
    &__text {
      position: static;
      width: 100%;
      height: 20px;

      color: #04153E;
      margin-top: 4px;
      margin-bottom: 12px;
    }
  }
}
.print {
  fill: #3D75E4;
  color: #3D75E4;
  cursor: pointer;
}
.margin-right {
  float: right;
  margin-top: 12px;
  margin-right: 12px;
  z-index: 3000;
  cursor: pointer;
}
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;

  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}

.ymap-container [class*="islands_icon___"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='36px' viewBox='0 0 24 36' version='1.1'%3e%3cpath d='M12,0 C18.62745,0 24,5.37255 24,12 C24,18.0768 21.377925,22.230675 19.402275,25.36065 C19.264725,25.5786 19.13025,25.791675 18.999975,26.000025 C17.2179,28.851375 12.467025,34.56105 11.45295,35.771325 C11.329125,35.919075 11.149575,36 10.956825,36 C10.4964,36 10.18035,35.535675 10.3395,35.10375 C13.399875,26.799675 12,24 12,24 C5.37255,24 0,18.62745 0,12 C0,5.37255 5.37255,0 12,0 Z M12,18 C15.313725,18 18,15.313725 18,12 C18,8.686275 15.313725,6 12,6 C8.686275,6 6,8.686275 6,12 C6,15.313725 8.686275,18 12,18 Z' id='Shape' fill='%233D75E4'/%3e%3ccircle id='Oval' fill='%23FFFFFF' fill-rule='nonzero' cx='12' cy='12' r='6'/%3e%3c/svg%3e") !important;
  background-size: 24px 36px !important;
  background-repeat: no-repeat;
  background-position: center center;
}

.ymap-container [class*="clusterSmallIcon"],
.ymap-container [class*="clusterMediumIcon"],
.ymap-container [class*="clusterLargeIcon"] {

  [class*="-svg-icon-content"] {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 13px !important;
    text-align: center !important;
    color: #FFFFFF !important;
  }

}

.ymap-container [class*="clusterSmallIcon"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='46px' height='46px' viewBox='0 0 64 64' version='1.1'%3e%3ctitle%3eMapPinGroup%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='MapPinGroup' fill-rule='nonzero'%3e%3ccircle id='Oval' fill='%23FFFFFF' cx='32' cy='32' r='32'/%3e%3ccircle id='Oval' fill='%233D75E4' cx='32' cy='32' r='26'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
  background-size: 46px 46px !important;

  [class*="-svg-icon-content"] {
    font-size: 20px !important;
  }
}

.ymap-container [class*="clusterMediumIcon"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='58px' height='58px' viewBox='0 0 64 64' version='1.1'%3e%3ctitle%3eMapPinGroup%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='MapPinGroup' fill-rule='nonzero'%3e%3ccircle id='Oval' fill='%23FFFFFF' cx='32' cy='32' r='32'/%3e%3ccircle id='Oval' fill='%233D75E4' cx='32' cy='32' r='26'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
  background-size: 58px 58px !important;

  [class*="-svg-icon-content"] {
    font-size: 22px !important;
  }
}

.ymap-container [class*="clusterLargeIcon"] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='71px' height='71px' viewBox='0 0 64 64' version='1.1'%3e%3ctitle%3eMapPinGroup%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='MapPinGroup' fill-rule='nonzero'%3e%3ccircle id='Oval' fill='%23FFFFFF' cx='32' cy='32' r='32'/%3e%3ccircle id='Oval' fill='%233D75E4' cx='32' cy='32' r='26'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
  background-size: 71px 71px !important;
}

/*
.ymap-container [class*="pie-chart-content"]{

  font-weight: 800;
  font-size: 24px;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;
}*/
.ymaps-2-1-79-graphics-SVG {
  stroke-width: 2px;
}
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;

  font-size: 24px;
  line-height: 28px;
}
.cursor-crosshair .ymaps-2-1-79-map ymaps{
  cursor: crosshair!important;
}
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Golos UI';
  font-size: 24px;
  line-height: 28px;
}

</style>
