import Vue from 'vue';
import Rir from '@rir/vue-library';
// import '@rir/vue-library/dist/RirLib.css';
// import Rir from './vue-library/RirLib.umd.min';
//import './vue-library/RirLib.css';
if (process.env.NODE_ENV === 'development') {
  import('@rir/vue-library/dist/RirLib.css');
}
Vue.use(Rir);

const options = {};
export default new Rir(options);
/*
// import('./ui/RirUI.css');
import library from './ui/RirUI.umd.min';
//import library from '../../../ui-kit/dist/RirUI.umd.min';
if (process.env.NODE_ENV === 'development') {
  //import('./ui/RirUI.css');
  //import('../../../ui-kit/dist/RirUI.css');
}
export default {
  async install(Vue) {
    const { Components, Directives, Mixins } = library;
    Object.keys(Components).forEach(name => {
      Vue.component(Components[name].name, Components[name]);
    });
    Object.entries(Directives).forEach(directive => {
      Vue.use(directive);
    });
    Object.values(Mixins).forEach(mixin => {
      Vue.mixin(mixin);
    });
  }
};
*/
